import React, { useState, useEffect, useCallback, MouseEvent } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, Label, } from "reactstrap";
import CustomMUIDatePicker from "../../components/UI/CustomMUIDatePicker";
import CreateHospital from "./CreateHospital";
import HospitalCardView from "../../components/hospitalComponents/HospitalCardView";
import SingleHospital from "./SingleHospital";
import HospitalGridView from "../../components/hospitalComponents/HospitalGridView";
import { all_hospitals_without_pagination, deleteHospital } from "../../redux/actions/hospitalAction";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../redux/types/hospitalTabsTypes";
import HospitalHeader from "../../components/Header/HospitalHeader";
import { getCountryList } from "../../redux/actions/countryAction";
import { ErrorToast, SuccessToast } from "../../utils/toastNotifications";
import { ERROR_MESSAGES } from "../../helpers/constants";
import { hospital_access } from "../../helpers/access_policies";
import NoAccess from "../../components/NoAccess";
import hospital from "../../assets/images/nodata-found/hospital.png";
import DeleteConfirmationModal from "../../components/DeletePopupComponent/DeletePopup";
import { AnyAaaaRecord } from "dns";
import Header from "../../components/Header/Header";
import SideHeader from "../../components/Header/SideHeader";
import HospitalSubHeader from "../../components/Header/HospitalSubHeader";
import SvgFilesIcon from "../../components/SvgFiles/SvgFiles";

const Hospitals = () => {
  const auth_token = localStorage.getItem("tele_icu_token");
  const [subTab, setsubTab] = useState("1");
  const [isHospitalEditMode, setIsHospitalEditMode] = useState(false);

  const [showHospitalPropertyModal, setShowHospitalPropertyModal] = useState(false);
  const [showSingleHospitalPropertyModal, setSingleShowHospitalPropertyModal] = useState(false);

  const [hospitals, sethospitals] = useState([]);
  const [hospital_loading, sethospital_loading] = useState(false);
  const [hospitals_data, sethospitals_data] = useState([]);
  const [countries, setcountries] = useState([]);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [filters, setFilters] = useState({ start_date: "", end_date: "", sort_order: "" });
  const { user_info } = useSelector((state: any) => state.user);
  const dispatch: any = useDispatch();
  const { HospitalTabs, HospitalTab, currentActiveTab } = useSelector((state: any) => state.hospitalTab);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hospitalToDelete, setHospitalToDelete] = useState<any>(null);


  const toggleDeleteModal = (hospital: any) => {
    setHospitalToDelete(hospital);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteConfirmation = async () => {
    if (hospitalToDelete) {
      const request = await deleteHospital(hospitalToDelete.id);
      if (request?.statusCode === 200) {
        await fetch_hospitals();
        SuccessToast(request?.message);
        dispatch({ type: ActionTypes.REMOVE_HOSPITAL_TAB, payload: hospitalToDelete });
        if (hospitalToDelete?.id === currentActiveTab) {
          toggle("1");
        }
      } else {
        if (request?.errors?.length > 0) {
          ErrorToast(request?.errors[0]?.msg);
        }
      }
      setShowDeleteModal(false);
    }
  };

  const handleDeleteHospital = async (e: MouseEvent<HTMLElement>, hospital: any) => {
    e.stopPropagation();
    toggleDeleteModal(hospital);
  };

  // Toggle active state for Tab
  const toggle = useCallback((tab: string) => {
    if (currentActiveTab !== tab) {
      dispatch({
        type: ActionTypes.SET_CURRENT_ACTIVE_TAB,
        payload: {
          id: tab,
        },
      });
    }
  },
    [currentActiveTab, dispatch]
  );

  const subTabtoggle = (tab: string) => {
    if (subTab !== tab) setsubTab(tab);
  };

  const search_hospital = (search_text: any) => {
    if (search_text === "") {
      sethospitals(hospitals_data);
    } else {
      let filter_hospitals = hospitals_data.filter((hospital: any) => {
        return hospital?.name
          ?.toLowerCase()
          .includes(search_text?.toLowerCase());
      });
      sethospitals(filter_hospitals);
    }
  };

  const fetch_hospitals = async () => {
    sethospital_loading(true);
    const request = await all_hospitals_without_pagination();
    if (request?.statusCode === 200) {
      sethospitals(request?.body?.data);
      sethospitals_data(request?.body?.data);
      console.log("123", request?.body?.data[0].name);

      sethospital_loading(false);
    } else {
      sethospitals([]);
      sethospitals_data([]);
      sethospital_loading(false);
    }
  };

  useEffect(() => {
    if (currentActiveTab === "1") {
      fetch_hospitals();
    }
  }, [auth_token, currentActiveTab]);

  const add_hospital_tab = useCallback(
    (tab: any) => {
      dispatch({
        type: ActionTypes.ADD_HOSPITAL_TAB,
        payload: tab,
      });
    },
    [dispatch]
  );

  const handleFilterApply = async () => {
    sethospital_loading(true);
    const { start_date, end_date, sort_order } = filters;

    if ((start_date && !end_date) || (!start_date && end_date)) {
      ErrorToast(ERROR_MESSAGES.missingDates);
      sethospital_loading(false);
      return;
    }

    if (start_date && end_date) {
      if (!start_date.trim() || !end_date.trim()) {
        ErrorToast(ERROR_MESSAGES.emptyDates);
        sethospital_loading(false);
        return;
      }

      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      if (endDateObj < startDateObj) {
        ErrorToast(ERROR_MESSAGES.endDateLessThanStartDate);
        sethospital_loading(false);
        return;
      }
    }

    const request = await all_hospitals_without_pagination(
      start_date,
      end_date,
      sort_order
    );
    if (request?.statusCode === 200) {
      sethospitals(request?.body?.data);
      sethospitals_data(request?.body?.data);
      sethospital_loading(false);
    } else {
      sethospitals([]);
      sethospitals_data([]);
      sethospital_loading(false);
    }
  };

  const handleFilterChange = (name: string, value?: string) => {
    setFilters((prev_filters) => ({ ...prev_filters, [name]: value }));
  };

  const handleFilterReset = () => {
    setFilters({ start_date: "", end_date: "", sort_order: "ASC" });
    fetch_hospitals();
  };

  const handleEditHospital = (e: MouseEvent<HTMLElement>, hospital: any) => {
    e?.stopPropagation();
    setIsHospitalEditMode(true);
    const tab_info = { id: hospital?.id, name: hospital?.name };
    if (HospitalTabs?.some((tab: any) => tab?.id === hospital?.id)) {
      toggle(hospital?.id);
      dispatch({ type: ActionTypes.SET_HOSPITAL_TAB, payload: tab_info });
    } else {
      add_hospital_tab(tab_info);
      toggle(hospital?.id);
      dispatch({ type: ActionTypes.SET_HOSPITAL_TAB, payload: tab_info });
    }
  };

  // const handleDeleteHospital = async (e: MouseEvent<HTMLElement>, hospital: any) => {
  //   e?.stopPropagation();
  //   const request = await deleteHospital(hospital?.id);
  //   if (request?.statusCode === 200) {
  //     await fetch_hospitals();
  //     SuccessToast(request?.message);
  //     dispatch({ type: ActionTypes.REMOVE_HOSPITAL_TAB, payload: hospital });
  //     if (hospital?.id === currentActiveTab) {
  //       toggle("1");
  //     }
  //   } else {
  //     if (request?.errors?.length > 0) {
  //       ErrorToast(request?.errors[0]?.msg);
  //     }
  //   }
  // };


  return (
    <>
      <Header HeaderName="Hospitals" />
      <React.Fragment>
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          toggle={() => setShowDeleteModal(!showDeleteModal)}
          pageName={hospitalToDelete?.name}
          onConfirm={handleDeleteConfirmation} />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {hospital_loading && (
                <div className="loader-box">
                  <span className="loader"></span>
                </div>
              )}
              <TabContent
                activeTab={currentActiveTab ? currentActiveTab : "1"}
                className="text-muted main-tabcontent"
              >
                <TabPane tabId="1">
                  <div className="row">
                    <div className="col-12 col">
                      <div className="page-title-box common-top-fix-title d-sm-flex align-items-center justify-content-between only-create-title ">
                        <HospitalSubHeader />
                        <HospitalHeader />
                      </div>
                    </div>
                    <div className="col-12 col common-top-con">
                      <TabContent activeTab={subTab} className="text-muted">
                        <div className="page-title-right d-flex serchbar-panel-hospital">
                          {/* <div className="mr-2 patient-search-box">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search Hospital"
                              onChange={(e: any) => search_hospital(e.target.value)}
                            ></input>
                          </div> */}
                          <div className="mr-2 patient-search-box">
                            <div className="search-box-wrapper">
                            <div className="search-hospital-icon">
                              <SvgFilesIcon iconname={"search"} />
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search Role"
                                onChange={(e: any) => search_hospital(e.target.value)}
                              />
                            </div>
                          </div>
                          </div>
                          <div className="mr-2">
                            <button
                              type="submit"
                              onClick={() => setShowFilterBox(
                                (showFilterBox) => !showFilterBox
                              )}
                              className="btn btn-outline-light"
                            >
                              <i className="mdi mdi-filter-outline"></i> Filter
                              <i className="mdi mdi-chevron-down" />
                            </button>
                            {showFilterBox && (
                              <div className="filter-box">
                                <button
                                  className="close-btn btn btn-sm btn-soft-secondary waves-effect waves-light "
                                  onClick={() => setShowFilterBox(false)}
                                ><i className="mdi mdi-close" />
                                </button>
                                <div className="row">
                                  <div className="col-6 mb-3">
                                    <Label className="form-label">
                                      From Date
                                    </Label>
                                    <CustomMUIDatePicker
                                      name="start_date"
                                      type="formik"
                                      value={filters?.start_date}
                                      setState={handleFilterChange} />
                                  </div>
                                  <div className="col-6 mb-3">
                                    <Label className="form-label">To Date</Label>
                                    <CustomMUIDatePicker
                                      name="end_date"
                                      type="formik"
                                      value={filters?.end_date}
                                      setState={handleFilterChange} />
                                  </div>
                                  <div className="col-12 mb-3">
                                    <Label className="form-label">
                                      Filter By Order
                                    </Label>
                                    <select
                                      onChange={(e) => handleFilterChange("sort_order", e.target.value)}
                                      className="form-control"
                                      name="sort_order"
                                      value={filters.sort_order}
                                    >
                                      <option disabled selected>
                                        Select Order
                                      </option>
                                      <option
                                        style={{ color: "black" }}
                                        value="ASC"
                                      >
                                        Ascending
                                      </option>
                                      <option
                                        style={{ color: "black" }}
                                        value="DESC"
                                      >
                                        Decending
                                      </option>
                                    </select>
                                  </div>
                                  <div className="col-12 text-center">
                                    <button
                                      type="button"
                                      onClick={() => handleFilterApply()}
                                      className="btn btn-info medium-btn mr-2"
                                    >
                                      Apply
                                    </button>
                                    <button
                                      onClick={() => handleFilterReset()}
                                      type="button"
                                      className="btn btn-secondary medium-btn"
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <Nav tabs className="nav-tabs-custom list-view-tab nav-justified">
                            <NavItem>
                              <NavLink
                                className={subTab === "1" ? "active" : ""}
                                onClick={() => {
                                  subTabtoggle("1");
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  <i className="mdi mdi-grid"></i>
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={subTab === "2" ? "active" : ""}
                                onClick={() => {
                                  subTabtoggle("2");
                                }}
                              >
                                <span className="d-none d-sm-block">
                                  {" "}
                                  <i className="mdi mdi-format-list-bulleted"></i>
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </div>

                        {user_info?.user_access?.includes(
                          hospital_access?.view_all_hospitals
                        ) ? (
                          <>
                            <TabPane tabId="1">
                              <div className="scroll-bar-hospital patient-card-box hospital-card-box">
                                {hospitals?.length > 0 ? (
                                  hospitals?.map((hospital: any) => {
                                    const tab_info = { id: hospital?.id, name: hospital?.name };
                                    return (
                                      <div
                                        className="patient-box"
                                        key={hospital?.id}
                                        onClick={() => {
                                          if (HospitalTabs?.some((tab: any) => tab?.id === hospital?.id)) {
                                            toggle(hospital?.id);
                                            dispatch({
                                              type: ActionTypes.SET_HOSPITAL_TAB,
                                              payload: tab_info,
                                            });
                                          } else {
                                            add_hospital_tab(tab_info);
                                            toggle(hospital?.id);
                                            dispatch({
                                              type: ActionTypes.SET_HOSPITAL_TAB,
                                              payload: tab_info,
                                            });
                                          }
                                          setIsHospitalEditMode(false);
                                          setShowHospitalPropertyModal(false);
                                          setSingleShowHospitalPropertyModal(false);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <HospitalCardView
                                          hospital={hospital}
                                          handleEditHospital={handleEditHospital}
                                          handleDeleteHospital={handleDeleteHospital} />
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div
                                  className="no-data-found"
                                  >
                                    <img src={hospital} width={80}></img>
                                    <h4 className="mt-2">No Hospitals Found</h4>
                                  </div>
                                )}
                              </div>
                            </TabPane>
                            <TabPane tabId="2">
                              <div className="row scroll-bar-hospital patient-list-view-section hospital-card-box">
                                <div className="col-md-12">
                                  <Card>
                                    <CardBody>
                                      <div className="table-responsive">
                                        <table className="table table-bordered  table-nowrap mb-0 table-list">
                                          <thead className="table-light">
                                            <tr>
                                              <th>Hospital Name & Address</th>
                                              <th>Contact Persoin</th>
                                              <th>Conatct Number</th>
                                              <th>Active Property</th>
                                              <th>Subscription Type</th>
                                              <th>Action</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {hospitals?.length > 0 ? (
                                              hospitals.map(
                                                (hospital: any, index: any) => {
                                                  const tab_info = {
                                                    id: hospital?.id,
                                                    name: hospital?.name,
                                                  };
                                                  return (
                                                    <tr
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        if (HospitalTabs?.some(
                                                          (tab: any) => tab?.id ===
                                                            hospital?.id
                                                        )) {
                                                          toggle(hospital?.id);
                                                          dispatch({
                                                            type: ActionTypes.SET_HOSPITAL_TAB,
                                                            payload: tab_info,
                                                          });
                                                        } else {
                                                          add_hospital_tab(
                                                            tab_info
                                                          );
                                                          toggle(hospital?.id);
                                                          dispatch({
                                                            type: ActionTypes.SET_HOSPITAL_TAB,
                                                            payload: tab_info,
                                                          });
                                                        }
                                                      }}
                                                      key={index}
                                                    >
                                                      <HospitalGridView
                                                        hospital={hospital}
                                                        handleEditHospital={handleEditHospital}
                                                        handleDeleteHospital={handleDeleteHospital} />
                                                    </tr>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <tr>
                                                <td>
                                                  <span>No Hospitals</span>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </div>
                            </TabPane>
                          </>
                        ) : (
                          <NoAccess text="Hospitals Access Restricted" />
                        )}
                      </TabContent>
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  {user_info?.user_access?.includes(
                    hospital_access.create_hospital
                  ) ? (
                    <CreateHospital countries={countries} toggle={toggle} />
                  ) : (
                    <NoAccess text="Create hospital Access Restricted" />
                  )}
                </TabPane>
                <TabPane tabId={HospitalTab?.id}>
                  {user_info?.user_access?.includes(
                    hospital_access.read_hospital
                  ) ? (
                    <SingleHospital
                      countries={countries}
                      showSingleHospitalPropertyModal={showSingleHospitalPropertyModal}
                      setSingleShowHospitalPropertyModal={setSingleShowHospitalPropertyModal}

                      setIsHospitalEditMode={setIsHospitalEditMode}
                      showHospitalPropertyModal={showHospitalPropertyModal}

                      setShowHospitalPropertyModal={setShowHospitalPropertyModal}
                      isHospitalEditMode={isHospitalEditMode}

                      hospital_id={HospitalTab?.id}
                      toggle={toggle}
                      handleDeleteHospital={handleDeleteHospital} />
                  ) : (
                    <NoAccess text="Hospital Details Access Restricted" />
                  )}
                </TabPane>

              </TabContent>
            </div>
          </div>
        </div>
      </React.Fragment >
    </>
  );
};
export default Hospitals;